import Head from "next/head";
import React, {useEffect} from 'react'
import App, {AppInitialProps} from 'next/app';
import '@/public/styles/index.scss'
import LayoutMain from "@/components/common/LayoutMain";
import LayoutEmpty from "@/components/common/LayoutEmpty";
import LayoutCustomer from "@/components/customer/LayoutCustomer";
import InitialData from "@/components/core/InitialData";
import {wrapper} from '@/store/store'
import {setBlogCategories} from 'store/blog/action'
import {isServerReq} from '@/lib/utils/isServerReq'
import {detectUserAgentIsRobot} from '@/lib/utils/detectUserAgentIsRobot'
import {request} from '@/lib/utils/request'
import GtmBody from '@/components/core/GtmBody'
import ReactNotification from 'react-notifications-component'
import {setSsrHost, setSsrRequestUrl, toggleIsMobileDevice, toggleIsUserAgentBot} from '@/store/core/action'
import 'public/styles/lib/slick.min.css'
import Loader from "@/components/core/Loader";

declare global {
    interface Window {
        prevUrl: any;
        dataLayer: any;
    }
}

let categories = [];

class WrappedApp extends App<AppInitialProps> {
    static isUserAgentBot: any;

    public static getInitialProps = wrapper.getInitialAppProps(store => async ({Component, ctx}) => {
        await WrappedApp.loadBlogCategories(store);

        const req = ctx?.req
        const res = ctx?.res

        const dispatch = store?.dispatch
        const headers = req?.rawHeaders
        const reqHost = req?.headers?.host

        if (reqHost.match(/^www/) !== null) {
            res.writeHead(301, {
                location: "http://" + reqHost.replace(/^www./, "") + req.url,
            });
            res.end();
        }

        const reqProtocol = reqHost?.includes('localhost') || reqHost?.includes('.local') ? 'http' : 'https'

        if (isServerReq(req)) {
            if (reqHost) {
                store.dispatch(setSsrHost(reqHost))
                store.dispatch(setSsrRequestUrl(`${reqProtocol}://${reqHost}${ctx.asPath.split('?')[0]}`))
            }
        }

        if (headers) {
            const userAgentIndex = headers.findIndex(el => (el === 'user-agent' || el === 'User-Agent'))
            const userAgent = headers[userAgentIndex + 1]

            // detecting if user agent is bot to hide some things for optimizations
            dispatch(toggleIsUserAgentBot(detectUserAgentIsRobot(userAgent)))

            dispatch(toggleIsMobileDevice(
                Boolean(userAgent.match(/Android|iPad|BlackBerry|iPhone|iPod|Opera Mini|IEMobile/i))
            ))
        }

        return {
            pageProps: {
                ...(Component.getInitialProps ? await Component.getInitialProps({...ctx, store}) : {}),
                path: ctx.asPath,
                pathname: ctx.pathname
            },
        };
    });

    private static async loadBlogCategories(store) {
        const categories = await request('/api/blog/categories', 'GET', null,true)

        store.dispatch(setBlogCategories(categories))
    }

    public render() {
        const {Component, pageProps} = this.props;

        let Layout = LayoutMain

        if (pageProps.path.includes('/customer/')) {
            Layout = LayoutCustomer;
        }

        if (pageProps.path.includes('/customer/register') ||
            pageProps.path.includes('/customer/login') ||
            pageProps.path.includes('/customer/reset-password') ||
            pageProps.path.includes('/customer/reset-password-success') ||
            pageProps.path.includes('/customer/login') ||
            pageProps.path.includes('/customer/details')) {
            Layout = LayoutEmpty;
        }

        return (
            <>
                <Head>
                    <title>Way Out</title>
                    <meta name='viewport' content='minimum-scale=1, initial-scale=1, maximum-scale=5, width=device-width, shrink-to-fit=no, viewport-fit=cover' />
                </Head>
                <ReactNotification/>
                <InitialData />
                <Loader/>
                <Layout>
                    <Component {...pageProps} />
                </Layout>
                {(process.env.NEXT_PUBLIC_GTM_ID !== 'TODO') &&
                <GtmBody gtmId={process.env.NEXT_PUBLIC_GTM_ID}/>}
            </>
        )
    };
}

export default wrapper.withRedux(WrappedApp);
